import React, { Fragment }  from 'react'
import LogoKK from '../images/LP/logo-kk.svg' 
import imgSucesso from '../images/LP/sucesso.png'
import imgSucessoM from '../images/LP/sucesso-m.svg'

import {
  column,
  columns,
  container,
  Sucessocont,
  content,
  mobile,
  desktop,
  logo
} from '../components/styles/Header.styles'

const Header = () => {
  return (
    <Fragment>
      <section css={logo}>
        <div css={container}>
            <div css={columns}>
              <div css={column}>
                <a href='/index'><img src={LogoKK} alt='' style={{ width: 160 }} /></a>
              </div>
            </div>
          </div>
      </section>
      <div css={[Sucessocont, content]}>
        <div css={container}>
          <div css={columns}>
            <div css={column} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <h3>Obrigado pelo seu interesse</h3>
              <h4>Nosso time comercial entrará em contato com você</h4>
              <div style={{ display: 'flex', justifyContent: 'center',marginTop: 40 }}>
                <a href='/'>VOLTAR AO SITE</a>
              </div>
              <img css={desktop} src={imgSucesso} alt='' />
              <img css={mobile} src={imgSucessoM} alt='' />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Header